/* eslint-disable no-alert */
<template>
  <b-card-code title="List des inscriptions">
    <!-- input search -->
    {{ admission }}
    <div class="custom-search d-flex justify-content-end">
      <b-col md="4">
        <b-form-group>
          <v-select
            v-model="admission"
            :clearable="false"
            :options="EtatsAdmission"
            placeholder="Chercher par etat d'admission"
          />
        </b-form-group>
      </b-col>

      <!-- <b-col md="2">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="btn-icon mr-5"
          @click="getLeadByEtat"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </b-col> -->
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block mb-1 mb-sm-0 mr-0 mr-sm-1"
          />

          <download-excel :data="users">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon"
            >
              <i class="far fa-file-excel fa-lg" />
            </b-button>
          </download-excel>

        </div>
      </b-form-group>
    </div>
    <!-- table -->

    <vue-good-table
      :columns="columns"
      :rows="users"
      :rtl="direction"
      primary-key="id"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          {{ props.row.id }}
        </div>
        <div v-else-if="props.column.field === 'etat'">
          <div v-if="props.row.admission === 'demande admission non envoyée'">
            <b-badge
              class="badge-glow"
              pill
              variant="danger"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> {{ props.row.admission }}</span>
            </b-badge>
            <br>
            <b-badge
              class="badge-glow mt-1"
              pill
              variant="warning"
            >
              <feather-icon
                icon="MailIcon"
                class="mr-25"
              />
              <span> Rappeler par email</span>
            </b-badge>
          </div>

          <div v-if="props.row.admission === 'demande admission envoyée'">
            <b-badge
              class="badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> {{ props.row.admission }} </span>
            </b-badge>
            <br>
            <b-badge
              class="badge-glow mt-1"
              pill
              variant="warning"
            >
              <feather-icon
                icon="BellIcon"
                class="mr-25"
              />
              <span> En attente de validation d'admission </span>
            </b-badge>
          </div>

          <div
            v-if="
              props.row.admission === 'demande admission acceptée' &&
                props.row.inscription === 'demande inscription non envoyée'
            "
          >
            <b-badge
              class="badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> {{ props.row.admission }} </span>
            </b-badge>
            <br>
            <b-badge
              class="badge-glow mt-1"
              pill
              variant="warning"
            >
              <feather-icon
                icon="BellIcon"
                class="mr-25"
              />
              <span> Attente de demande d'inscription </span>
            </b-badge>
          </div>

          <div
            v-if="
              props.row.admission === 'demande admission acceptée' &&
                props.row.inscription === 'demande inscription envoyée'
            "
          >
            <b-badge
              class="badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> {{ props.row.inscription }} </span>
            </b-badge>
            <br>
            <b-badge
              class="badge-glow mt-1"
              pill
              variant="warning"
            >
              <feather-icon
                icon="BellIcon"
                class="mr-25"
              />
              <span> Attente de validation d'inscription </span>
            </b-badge>
          </div>
          <div
            v-if="
              props.row.inscription === 'demande inscription acceptée' &&
                props.row.paiement === 'demande paiement non envoyée'
            "
          >
            <b-badge
              class="badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> {{ props.row.inscription }} </span>
            </b-badge>
            <br>
            <b-badge
              class="badge-glow mt-1"
              pill
              variant="warning"
            >
              <feather-icon
                icon="BellIcon"
                class="mr-25"
              />
              <span> Attente de paiement </span>
            </b-badge>
          </div>
          <div
            v-if="
              props.row.inscription === 'demande inscription acceptée' &&
                props.row.paiement === 'demande paiement envoyée'
            "
          >
            <b-badge
              class="badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> {{ props.row.paiement }} </span>
            </b-badge>
            <br>
            <b-badge
              class="badge-glow mt-1"
              pill
              variant="warning"
            >
              <feather-icon
                icon="BellIcon"
                class="mr-25"
              />
              <span> Attente de validation </span>
            </b-badge>
          </div>
          <div v-if="props.row.paiement === 'demande paiement acceptée'">
            <b-badge
              class="badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span> {{ props.row.paiement }} </span>
            </b-badge>
          </div>
          <div v-if="props.row.inscription === 'demande inscription refusée'">
            <b-badge
              class="badge-glow"
              pill
              variant="danger"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> {{ props.row.inscription }} </span>
            </b-badge>
          </div>
          <div v-if="props.row.paiement === 'demande paiement refusée'">
            <b-badge
              class="badge-glow"
              pill
              variant="danger"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span> {{ props.row.paiement }} </span>
            </b-badge>
          </div>
          <b-badge
            v-if="props.row.admission === 'demande admission refusée'"
            class="badge-glow"
            pill
            variant="danger"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span> {{ props.row.admission }} </span>
          </b-badge>
        </div>
        <!-- Column: Action -->

        <span v-else-if="props.column.field === 'action'">
          <feather-icon
            v-if="props.row.admission === 'demande admission non envoyée'"
            :id="`invoice-row-${props.row.id}-edit-icon`"
            icon="MailIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="sendMail(props.row.email, props.row.nom)"
          />
          <feather-icon
            v-if="props.row.admission === 'demande admission acceptée' && props.row.inscription === 'demande inscription non envoyée'"
            :id="`invoice-row-${props.row.id}-edit-icon`"
            icon="MailIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="sendMailInscription(props.row.email, props.row.nom)"
          />
          <feather-icon
            v-if="props.row.inscription === 'demande inscription acceptée' && props.row.paiement === 'demande paiement non envoyée'"
            :id="`invoice-row-${props.row.id}-edit-icon`"
            icon="MailIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="sendMailPaiement(props.row.email, props.row.nom)"
          />
          <b-tooltip
            title="Rappeler par email"
            class="cursor-pointer"
            :target="`invoice-row-${props.row.id}-edit-icon`"
          />
          <feather-icon
            v-if="
              props.row.admission === 'demande admission envoyée' ||
                props.row.admission === 'demande admission acceptée'
            "
            :id="`invoice-row-${props.row.id}-show-icon`"
            icon="BellIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'lead-show', params: { id: props.row.id } })"
          />
          <b-tooltip
            v-if="
              props.row.admission === 'demande admission envoyée' ||
                props.row.inscription === 'demande inscription envoyée'
            "
            title="Consulter et valider"
            class="cursor-pointer"
            :target="`invoice-row-${props.row.id}-show-icon`"
          />
          <b-tooltip
            v-if="
              props.row.admission === 'demande admission acceptée' &&
                props.row.inscription === 'demande inscription non envoyée'
            "
            title="Consulter"
            class="cursor-pointer"
            :target="`invoice-row-${props.row.id}-show-icon`"
          /> </span></template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '15']"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BTooltip,
  BButton,
  BBadge,
  BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { avatarText } from '@core/utils/filter'
import store from '@/store/index'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'
import Ripple from 'vue-ripple-directive'
import xlsx from 'xlsx'
import vSelect from 'vue-select'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTooltip,
    BButton,
    downloadExcel: JsonExcel,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userss: [],
      EtatsAdmission: [
        'demande admission non envoyée',
        'demande admission envoyée',
        'demande admission acceptée',
        'demande admission refusée',
        'demande inscription non envoyée',
        'demande inscription envoyée',
        'demande inscription acceptée',
        'demande inscription refusée',
        'demande paiement non envoyée',
        'demande paiement envoyée',
        'demande paiement acceptée',
        'demande paiement refusée',
      ],

      paiement: '',
      inscription: '',
      admission: '',
      is_superuser: false,
      is_staff: false,
      is_active: false,
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Nom',
          field: 'nom',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche nom',
          },
        },
        {
          label: 'Prénom',
          field: 'prenom',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche prenom',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche prenom',
          },
        },

        {
          label: 'Téléphone',
          field: 'telephone',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche téléphone',
          },
        },
        {
          label: 'Date de création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche cin',
          },
        },

        {
          label: 'Etat',
          field: 'etat',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Etat',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      avatarText,

    }
  },
  computed: {
    users() {
      if (this.admission === '') { return this.userss }
      if (this.admission === 'demande admission non envoyée' || this.admission === 'demande admission envoyée' || this.admission === 'demande admission refusée') {
        return this.userss.filter(user => user.admission === this.admission)
      }
      if (this.admission === 'demande admission acceptée') {
        return this.userss.filter(user => (user.admission === this.admission && user.inscription === 'demande inscription non envoyée'))
      }
      if (this.admission === 'demande inscription acceptée') {
        return this.userss.filter(user => (user.inscription === this.admission && user.paiement === 'demande paiement non envoyée'))
      }
      if (this.admission === 'demande inscription envoyée' || this.admission === 'demande inscription refusée') {
        return this.userss.filter(user => user.inscription === this.admission)
      }
      if (this.admission === 'demande inscription non envoyée') {
        return this.userss.filter(user => (user.admission === 'demande admission acceptée' && user.inscription === this.admission))
      }
      if (this.admission === 'demande paiement envoyée' || this.admission === 'demande paiement acceptée' || this.admission === 'demande paiement refusée') {
        return this.userss.filter(user => user.paiement === this.admission)
      }
      if (this.admission === 'demande paiement non envoyée') {
        return this.userss.filter(user => (user.inscription === 'demande inscription acceptée' && user.paiement === this.admission && user.admission === 'demande admission acceptée'))
      }
      return []
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  created() {
    this.is_superuser = localStorage.getItem('is_superuser')
    this.is_staff = localStorage.getItem('is_staff')
    this.is_active = localStorage.getItem('is_active')
    this.getUsers()
  },

  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)

      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')}`
    },
    chooseFiles() {
      document.getElementById('upload').click()
    },
    importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      }
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // eslint-disable-next-line consistent-return
        return this.AlertshowToast(
          'danger',
          'top-center',
          'Format du fichier est invalide !!!!',
        )
      }
      const fileReader = new FileReader()
      // eslint-disable-next-line consistent-return
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const XLSX = xlsx
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0] // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]) // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          const excellist = [] // Clear received data
          // Edit data
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < ws.length; i++) {
            excellist.push(ws[i])
          }
          // eslint-disable-next-line no-shadow
        } catch (e) {
          // eslint-disable-next-line no-alert
          return this.AlertshowToastt(
            'danger',
            'top-center',
            'La lecture du fichier a échoué !!!!',
          )
        }
      }
      fileReader.readAsBinaryString(files[0])
      const input = document.getElementById('upload')
      input.value = ''
    },
    getUsers() {
      axios.get('/auth/users/').then(response => {
        this.userss = response.data.filter(user => user.is_superuser === false)
      })
    },
    sendMail(email, nom) {
      axios.post('/auth/email/', { message: "Prière de remplir le formulaire d'admission. afin de réserver votre place à MIT", nom, email }).then(() => {
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Email envoyée avec succés')
        }, 1000)
      })
    },
    sendMailInscription(email, nom) {
      axios.post('/auth/email/', { message: "Veuillez completer rapidement votre inscription en remplissant le formulaire d'inscription pour reserver votre place dès maintenant.", nom, email }).then(() => {
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Email envoyée avec succés')
        }, 1000)
      })
    },
    sendMailPaiement(email, nom) {
      axios.post('/auth/email/', { message: 'Prière de remplir le formulaire de paiement. afin de réserver votre place à MIT', nom, email }).then(() => {
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Email envoyée avec succés')
        }, 1000)
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    AlertshowToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

    deleteService(persid) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(`/service/${persid}/`)
            .then(() => {
              this.getServices()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Service supprimé avec succés')
              }, 1000)
            })
            .catch(error => {
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelled',
            text: 'Your Item is safe :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
